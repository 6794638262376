import * as React from "react";
import {FC, InputHTMLAttributes} from "react";
import {useAppSelector} from "../../../../store/hook";
import {IFormFieldProps} from "../../IFormFieldProps";
import Element from "../../../../domain/Element/Element";
import parse from "html-react-parser";
import Checkbox from "../checkbox/Checkbox";
import Input from "../input/Input";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    element: Element
    formData: Record<string, unknown> | []
    register: (() => void) | undefined
    clearErrors: (id: string | undefined) => void
}

const PercentageInputInteger: FC<IProps> = (
    {
        element,
        formData,
        required,
        register,
        clearErrors,
        error
    }
) => {

    const elementsFromStoreString = useAppSelector(state => state.elements)
    const elementsFromStore = JSON.parse(elementsFromStoreString.currentElements)

    const attributes = element?.attributes

    const elementDefaultValue = (element) => (
        Number(
            (formData && formData[element.id])
                ? formData[element.id]
                : attributes?.defaultValue
        )
    )

    const elementStartValue = (element) => (
        elementDefaultValue(element)
            ? elementDefaultValue(element)
            : element.attributes?.start
    )

    const decisionElement: Element | undefined = elementsFromStore.find(
        (storedElement) => storedElement.id === element?.attributes?.childElementId
    )

    const isDefaultValueBoolean = (decisionElement?.attributes?.defaultValue === 'true')

    return (
        <>
            <div className={element.cssClasses + ` form-floating`}>
                <div className={`percentage-range`} title={attributes?.help}>
                    <div className={`percentage-range-label`}>
                        <label>{parse(attributes?.label ?? '')}</label>
                    </div>
                    <div className={`percentage-range-content`}>
                        <div className={`flex-container`}>
                            {
                                decisionElement &&
                                <Checkbox
                                    id={decisionElement.id}
                                    label={decisionElement.attributes?.label}
                                    classes={decisionElement.cssClasses}
                                    name={decisionElement.name}
                                    error={error}
                                    register={register}
                                    defaultChecked={(formData && typeof formData[decisionElement.id] !== 'undefined') ? ('true' === formData[element.id] || ('boolean' === typeof formData[decisionElement.id] && formData[decisionElement.id])) : isDefaultValueBoolean}
                                    help={decisionElement.attributes?.help}
                                    readonly={decisionElement.attributes?.readonly}
                                />
                            }
                            <Input
                                classes={'col-xs-12 percentage'}
                                register={register}
                                clearErrors={clearErrors}
                                type={'number'}
                                pattern='[0-9]*'
                                min={attributes?.min}
                                max={attributes?.max}
                                error={error}
                                id={element.id}
                                name={element.name}
                                placeholder={attributes?.label}
                                required={required}
                                readonly={attributes?.readonly}
                                help={attributes?.help}
                                defaultValue={(formData && formData[element.id]) ? Number(formData[element.id]) : attributes?.defaultValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PercentageInputInteger