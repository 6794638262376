import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';

import Block from '../../../domain/Block/Block';
import Element from '../../../domain/Element/Element';
import ElementFactory from '../elements/ElementFactory';
import FetchElementGateway from '../../../gateway/Element/FetchElementGateway';
import PostUSeCase from '../../../domain/Form/PostUseCase'
import FormGateway from '../../../gateway/Form/FormGateway';
import FindUseCase from '../../../domain/Form/FindUseCase';
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway';

// Redux
import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {updateProgressValueById, updateNextPageId} from '../../../store/wizzard/wizzardStep';
import {updateBlock} from '../../../store/block/blocks';
import {setCurrent} from '../../../store/element/elements';

import storage from '../../util/storage';
import Loader from '../loader/Loader';
import {setTimestamp} from '../../../store/header/header';
import {EElementType} from "../../../domain/Element/EElementType";
import {EFolderStatus} from "../../../domain/Folder/EFolderStatus";

type Props = {
    pageId: string,
    block: Block,
};

const VOISIN_BLOCK_MISSION_VALIDATION = 'e176f758-ec12-49d3-92aa-0a8e5b63986c';
const VOISIN_BLOCK_SIGNATURE = 'cecfa1cc-748f-4998-a20c-8a909a6a6781';

const BlockFormComponent: FunctionComponent<Props> = ({pageId, block}) => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const {t} = useTranslation()

    const {register, handleSubmit, setError, errors, clearErrors, control} = useForm()
    const [elements, setElements] = useState<Element[] | null>(null);
    const [formData, setFormData] = useState<Record<string, unknown> | [] | null>(null);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [genericError, setGenericError] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false)

    const storeWizardStepNextPageId = useAppSelector(state => state.wizzardStep.nextPageId)
    const storeBlocksVisible = useAppSelector(state => state.blocks.blocksVisible)
    const storeBlocksVisibleLength = useAppSelector(state => state.blocks.blocksVisibleLength)

    const blockRef = useRef<HTMLFormElement>(null)

    const handleClick = () => {
        if (null !== blockRef.current) {
            window.scrollTo({top: (blockRef?.current?.offsetTop || 0) - 100, behavior: 'smooth'})
            setIsScrolled(true)
        }
    }

    useEffect(() => {
        (new FetchElementGateway()).getElementsByBlockId(block.id).then(elements => {
            dispatch(setCurrent({
                'currentElements': JSON.stringify(elements),
                'currentElementsWithCondition': JSON.stringify(elements?.filter(element => null !== element.condition)),
                'currentElementsWithCalcule': JSON.stringify(elements?.filter(element => '' !== element.calculate)),
                'currentElementsWithReference': JSON.stringify(elements?.filter(element => '' !== element.reference))
            }));

            setElements(elements)

            const findUseCase = new FindUseCase(new FormGateway());
            findUseCase.execute().then(data => {
                setFormData((data) ?? [])

                if (!isScrolled) {
                    handleClick()
                }
            })
        })
    }, [dispatch, pageId, block.id]);

    const onSubmit = async (data, e) => {
        setLoading(true)
        let hasErrors = false

        if (block.id === '48ea4516-ae8a-4030-9aad-661295b74c01') {
            const inputFilesList = document.querySelectorAll('input[type=file]');
            const uploadersWithMultipleOptions = ["64b9a000-ce7b-4ce6-9ba8-d5ef9bed7032"];

            inputFilesList.forEach(function (inputFile) {
                if (!storage.getReceipts(inputFile.id) && inputFile.id !== '09ae70ed-3c78-403d-9993-3ca7b14ae147' && !(inputFile.id in uploadersWithMultipleOptions)) {
                    hasErrors = true
                    setError(inputFile.id, {
                        type: "manual",
                        message: "Merci de téléverser ce document obligatoire"
                    })
                }
            });

            uploadersWithMultipleOptions.map(id => {
                if ((!storage.getReceipts(id))) {
                    hasErrors = true;
                    setError(id, {
                        type: "manual",
                        message: "Merci de téléverser ce document obligatoire"
                    })
                }
            });
        }

        if (block.id === '968757f1-390b-4d94-9773-6b18c57a2f1e') {
            if (!storage.getReceipts('1715a908-08e3-4dfd-927a-57ba84d8fbea')) {
                hasErrors = true
                setError('1715a908-08e3-4dfd-927a-57ba84d8fbea', {
                    type: "manual",
                    message: "Merci de téléverser ce document obligatoire"
                })
            }

            if (!storage.getReceipts('2fbfde04-4a3a-4b99-b458-c7d3c63d460b')) {
                hasErrors = true
                setError('2fbfde04-4a3a-4b99-b458-c7d3c63d460b', {
                    type: "manual",
                    message: "Merci de téléverser ce document obligatoire"
                })
            }
        }

        // Mise en place d'une condition temporaire
        // Pour vérifier si le téléphone du cosous
        // est différent de celui non cosous
        if (data['d1f92caa-69e5-4042-995f-298c3e0bc802']) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003']
                    && formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003'] === data['d1f92caa-69e5-4042-995f-298c3e0bc802']
                ) {
                    hasErrors = true
                    setError('d1f92caa-69e5-4042-995f-298c3e0bc802', {
                        type: "manual",
                        message: "Pour pouvoir signer la souscription en ligne, il est nécessaire d'avoir 2 numéros de téléphones différents"
                    })
                }
            }
        }

        // Mise en place d'une condition temporaire
        // Pour vérifier si le téléphone du conjoint
        // est différent de celui du souscripteur
        if (data['b304fd9a-e0e5-433c-9c04-19c4fec52e49']) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003']
                    && formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003'] === data['b304fd9a-e0e5-433c-9c04-19c4fec52e49']
                ) {
                    hasErrors = true
                    setError('b304fd9a-e0e5-433c-9c04-19c4fec52e49', {
                        type: "manual",
                        message: "Le téléphone du conjoint doit être différents du téléphone du souscripteur"
                    })
                }
            }
        }

        // Mise en place d'une condition temporaire
        // Pour vérifier si le mail du cosous
        // est différent de celui non cosous
        if (data['1d5ef763-36b2-4b58-99d1-5b64eee36ca7']) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved['06fc91ae-9279-11eb-a8b3-0242ac130003']
                    && formDatasSaved['06fc91ae-9279-11eb-a8b3-0242ac130003'] === data['1d5ef763-36b2-4b58-99d1-5b64eee36ca7']
                ) {
                    hasErrors = true
                    setError('1d5ef763-36b2-4b58-99d1-5b64eee36ca7', {
                        type: "manual",
                        message: t('element.error.mail-must-be-different-from-co-sub')
                    })
                }
            }
        }

        // Mise en place d'une condition temporaire
        // Pour vérifier si le mail du conjoint
        // est différent de celui du souscripteur
        if (data['df2c744c-4da9-4b07-9de1-48fe3511dda3']) {
            const formDatasJSON = localStorage.getItem('form');

            if (formDatasJSON != null) {
                const formDatasSaved = JSON.parse(formDatasJSON)
                if (
                    formDatasSaved['06fc91ae-9279-11eb-a8b3-0242ac130003']
                    && formDatasSaved['06fc91ae-9279-11eb-a8b3-0242ac130003'] === data['df2c744c-4da9-4b07-9de1-48fe3511dda3']
                ) {
                    hasErrors = true
                    setError('df2c744c-4da9-4b07-9de1-48fe3511dda3', {
                        type: "manual",
                        message: t('element.error.mail-must-be-different-from-partner')
                    })
                }
            }
        }

        if (data['6cefd0ca-57b0-4bc5-98a8-63b1e79e0867']
            && data['507df8da-3685-4a95-8d43-54b8893f698c']
            && data['43d3ea2a-c452-4367-bd27-9c0e8908113d']
            && data['720e5818-c62d-4c76-99a0-41bc1f5330ad']
            && data['17ea0440-77d8-4eb0-9b4e-fcf26ecedbdf']) {

            const value1 = parseInt(data['6cefd0ca-57b0-4bc5-98a8-63b1e79e0867'])
            const value2 = parseInt(data['507df8da-3685-4a95-8d43-54b8893f698c'])
            const value3 = parseInt(data['43d3ea2a-c452-4367-bd27-9c0e8908113d'])
            const value4 = parseInt(data['720e5818-c62d-4c76-99a0-41bc1f5330ad'])
            const value5 = parseInt(data['17ea0440-77d8-4eb0-9b4e-fcf26ecedbdf'])
            const total = value1 + value2 + value3 + value4 + value5

            if (total !== 100) {
                hasErrors = true
                setError('6cefd0ca-57b0-4bc5-98a8-63b1e79e0867', {
                    type: "manual",
                    message: "La somme des champs doit faire 100%"
                })
                setError('507df8da-3685-4a95-8d43-54b8893f698c', {
                    type: "manual",
                    message: "La somme des champs doit faire 100%"
                })
                setError('43d3ea2a-c452-4367-bd27-9c0e8908113d', {
                    type: "manual",
                    message: "La somme des champs doit faire 100%"
                })
                setError('720e5818-c62d-4c76-99a0-41bc1f5330ad', {
                    type: "manual",
                    message: "La somme des champs doit faire 100%"
                })
                setError('17ea0440-77d8-4eb0-9b4e-fcf26ecedbdf', {
                    type: "manual",
                    message: "La somme des champs doit faire 100%"
                })
            }
        }

        for (const percentageGroupElement of getPercentageGroupElements(elements ?? [])) {
            const percentageInputs = getPercentageInputsByPercentageGroup(elements ?? [], percentageGroupElement);
            const sum = getPercentageInputsSum(percentageInputs, data);
            if ((percentageGroupElement.attributes?.min !== undefined && percentageGroupElement.attributes?.max !== undefined) &&
                !(percentageGroupElement.attributes.min <= sum && sum <= percentageGroupElement.attributes.max)) {
                hasErrors = true;
                const errorMessage = percentageGroupElement.attributes.errorMessage || t('element.error.percentage-range-group-sum', {
                    min: percentageGroupElement.attributes.min,
                    max: percentageGroupElement.attributes.max
                });
                setError(percentageGroupElement.id, {
                    type: 'manual',
                    message: errorMessage
                });
            }
        }

        const fetchFolderGateway = new FetchFolderGateway();
        if ([VOISIN_BLOCK_MISSION_VALIDATION, VOISIN_BLOCK_SIGNATURE].includes(block.id)) {
            const folderResponse: any = await fetchFolderGateway.getFolder(String(storage.getFolderId()));
            if ((block.id === VOISIN_BLOCK_MISSION_VALIDATION && folderResponse.dossier.status !== EFolderStatus.Mission_validated) ||
                (block.id === VOISIN_BLOCK_SIGNATURE && folderResponse.dossier.status !== EFolderStatus.Signed)) {
                hasErrors = true;
                setGenericError('Afin de finaliser cette étape nous avons besoin que les parties prenantes signent. Une fois cela réalisé vous pourrez passer à l\'étape suivante.');
            }
        }

        if (hasErrors) {
            document.querySelector('.u-txt-color-error')?.scrollIntoView({behavior: 'smooth', block: "center"})
            setLoading(false)
            return false
        }

        const postUseCase = new PostUSeCase(new FormGateway(), fetchFolderGateway);
        try {
            await postUseCase.execute(data, block.id);e.target.closest('.box-elevations').classList.add('box-elevations--closed')

            setTimeout(() => {
                const blockCompleteBeforeModifyBlock = (JSON.parse(storeBlocksVisible)).findIndex(blockFromStore => blockFromStore.id === block.id)
                const progressPourcentage = ((blockCompleteBeforeModifyBlock + 1) / storeBlocksVisibleLength) * 100
                dispatch(updateBlock({'id': block.id, 'state': 'complete', 'stateNext': 'progress'}))
                dispatch(updateProgressValueById({'id': pageId, 'progress': progressPourcentage}))
                dispatch(setTimestamp({'timestamp': Date.now()}))

                if (progressPourcentage === 100) {
                    dispatch(updateNextPageId({'id': storeWizardStepNextPageId}))
                    history.push(`/form/${storeWizardStepNextPageId}`)
                }
            }, 800)
        } catch (e) {
            setLoading(false)
            setGenericError(t('error.technical-error'))
        }
    };
    const onError = (errors) => {
        const firstKey = Object.keys(errors)[0]

        if ((errors[firstKey].ref).type === 'hidden') {
            document.querySelector('.errors-custom-generated-for-hidden')?.remove()
            const createErrorElement = document.createElement('span')
            createErrorElement.classList.add('help', 'u-txt-color-error')
            createErrorElement.innerText = errors[firstKey].message;
            const createErrorElementWrapper = document.createElement('div')
            createErrorElementWrapper.classList.add('col-md-12', 'errors-custom-generated-for-hidden')
            createErrorElementWrapper.appendChild(createErrorElement);
            ((errors[firstKey].ref).parentNode).appendChild(createErrorElementWrapper)
        }

        if (undefined !== errors[firstKey].ref && errors[firstKey].ref.scrollIntoView != undefined) {
            (errors[firstKey].ref).parentNode.scrollIntoView({behavior: 'smooth', block: "center"})
        }

        setLoading(false)
    };

    const childElement: (parentElement: Element) => Element|undefined = (
        parentElement
    ) => {
        const childElementId:string = parentElement.attributes?.childElementId ?? ''
        const childElement:Element|undefined = elements?.find(
            (element) => element.id ===childElementId
        )
        return childElement
    }

    const childrenElements: (parentElement: Element) => (Element|undefined)[]|undefined = (
        parentElement
    ) => {
        const childrenElementsIds:string[] = parentElement.attributes?.childrenElementsIds ?? []
        const childrenElements = elements && childrenElementsIds.map(
            (elementId) => elements.find((element) => element.id ===elementId)
        )
        return childrenElements?.filter(element => element !== undefined)
    }

    const multiChildParentsElements:Element[]|undefined = elements?.filter(
        (element) => Array.isArray(element.attributes?.childrenElementsIds)
    )

    const uniqueChildParentsElements:Element[]|undefined = elements?.filter(
        (element) => element.attributes?.childElementId
    )

    const multiChildParentsChildrenElementsIds:(string|undefined)[]|undefined = multiChildParentsElements?.map(
        (parentElement) => parentElement.attributes?.childrenElementsIds
    ).flat()

    const uniqueChildParentsChildrenElementsIds:(string|undefined)[]|undefined = uniqueChildParentsElements?.map(
        (parentElement) => parentElement.attributes?.childElementId
    ).flat()


    const childrenElementsIds =  multiChildParentsChildrenElementsIds?.concat(uniqueChildParentsChildrenElementsIds)

    const parentsOnlyElements:Element[]|undefined = elements?.filter(
        (element) =>  childrenElementsIds?.find(
            (childElementId) => childElementId === element.id
        ) === undefined
    )

    return (
        <>
        {
            elements && formData &&
            <form onSubmit={handleSubmit(onSubmit, onError)}
                  className="form--grey-7"
                  ref={blockRef}
                  noValidate={true}
            >
                <div className={'flex-container'}>
                    {
                        parentsOnlyElements?.map(
                            (element, index) => {
                                return (
                                    <ElementFactory
                                        key={index}
                                        blockId={block.id}
                                        element={element}
                                        childElement={childElement(element)}
                                        childrenElements={childrenElements(element)}
                                        register={register}
                                        clearErrors={clearErrors}
                                        formData={formData}
                                        control={control}
                                        error={errors[element.id]}/>
                                )
                            }
                        )
                    }
                </div>
                <div className="flex-container center-md u-mtl u-relative">
                    {
                        genericError !== '' &&
                        <p className="notification notification--error">{genericError}</p>
                    }
                    {
                        errors.files &&
                        <p className="notification notification--error u-pointer"
                           onClick={() => clearErrors('files')}
                        >
                            {errors.files.message}
                        </p>
                    }
                    {
                        block.id !== 'e497fd89-4b40-4e99-9e7a-b2f63ec9d669' &&
                        block.id !== 'c2fc93ff-1e3e-499e-a060-a111668601b5' &&
                        undefined === errors.files &&
                        <>
                            <div className="col-md-12">
                                {(
                                    !isLoading &&
                                    <button type="submit" className="button button-primary button--large u-mxAuto">
                                        {t('button.keep-on')}
                                    </button>
                                )}
                                {(
                                    isLoading &&
                                    <Loader/>
                                )}
                            </div>
                            <div className="illustration illustration--profile"
                                 dangerouslySetInnerHTML={{__html: block.icon}}>
                            </div>
                        </>
                    }
                    {block.id === 'e497fd89-4b40-4e99-9e7a-b2f63ec9d669' && undefined === errors.files &&
                        <>
                            <div className="illustration" dangerouslySetInnerHTML={{__html: block.icon}}>
                            </div>
                        </>
                    }
                    {block.id === 'c2fc93ff-1e3e-499e-a060-a111668601b5' && undefined === errors.files &&
                        <>
                            <div className="col-md-12">
                                {(!isLoading &&
                                    <button type="submit"
                                            className="button button-primary button--large u-mxAuto"
                                    >
                                        {t('button.begin')}
                                    </button>
                                )}
                                {(isLoading &&
                                    <Loader/>
                                )}
                            </div>
                            <div className="illustration illustration--profile"
                                 dangerouslySetInnerHTML={{__html: block.icon}}>
                            </div>
                        </>
                    }
                </div>
            </form>
        }
    </>)
}

const getPercentageGroupElements = (elements: Element[]): Element[] => {
    return elements.filter(element => element.type === EElementType.GROUP_PERCENTAGE_INPUT_RANGE);
}

const getPercentageInputsByPercentageGroup = (elements: Element[], percentageRangeGroup: Element): Element [] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return elements.filter(element => percentageRangeGroup.attributes?.childrenElementsIds.includes(element.id));
}

const getPercentageInputsValue = (percentageInputs: Element[], data: Record<string, any>) => {
    const percentageInputsWithCheckboxCheckedOrWithoutCheckbox = percentageInputs.filter(
        percentageInput => percentageInput.attributes?.childElementId ?
            data[percentageInput.attributes.childElementId] === true :
            true
    );
    return percentageInputsWithCheckboxCheckedOrWithoutCheckbox.map(
        percentageInput => Number.isInteger(parseInt(data[percentageInput.id])) ?
            parseInt(data[percentageInput.id]) :
            0
    );
}

const getPercentageInputsSum = (percentageInputs: Element[], data: Record<string, any>) => {
    return getPercentageInputsValue(percentageInputs, data).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
    );
}

export default BlockFormComponent;
